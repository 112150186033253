import { Stack, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import packageJson from "../../../package.json";
import Header from "../header";

const APPVERSION = packageJson.version;

const ENV = process.env.REACT_APP_ENV;

// console.log(`ENV`);
// console.log(ENV);

export default function Layout() {
  return (
    <Wrapper>
      <Header />
      <main>
        <Outlet />
      </main>
      <Stack pt={1} pb={3}>
        <Typography variant="caption" color="white" textAlign="center">
          當前版本: {APPVERSION}
        </Typography>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 400px;
  height: calc(100dvh - 2rem);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
`;
