import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" gap={2}>
      <Typography variant="h2" fontWeight={900} color="white">
        404
      </Typography>
      <Typography variant="h4" fontWeight={900}>
        頁面不存在
      </Typography>
      {/* <Typography variant="h6" color="white">
        Oh, The page you are looking for does not exist.
      </Typography> */}
      <Button
        variant="outlined"
        href="/"
        sx={{
          backgroundColor: "waspYellow",
          color: "waspDark",
          fontWeight: 600,
          ":hover": {
            backgroundColor: "waspLightYellow",
            color: "waspLightDark",
          },
        }}
      >
        返回首頁
      </Button>
    </Stack>
  );
};

export default NotFoundPage;
