import { Box, Typography } from "@mui/material";
import { HTMLAttributes, useEffect, useState } from "react";
import styled from "styled-components";

import { getParkingItems } from "../../apis/parking";
import CarTypeCard from "./CarTypeCard";
import FulledCarTypeCard from "./FulledCarTypeCard";

interface IStepTwoProps {
  carParkId: string;
  orderItemId: string;
  carParkTitle: string;
  onCarTypeSelect: (detail: ICardTypeDetails) => void;
}

export interface ICardTypeDetails {
  orderItemId: string;
  categoryId: string;
  itemTypeId: string;
  parkingId: string;
  title: string;
  price: number;
  isFull: boolean;
  visible: boolean;
  deleted: boolean;
  quota: number;
  priceId: string;
  interval: string;
  interval_count: number;
}

const StepTwo = (props: IStepTwoProps) => {
  const {
    carParkId,
    orderItemId,
    carParkTitle,
    onCarTypeSelect,
    ...otherProps
  } = props;
  const [carTypeDetails, setCarTypeDetails] =
    useState<Array<ICardTypeDetails>>();
  // Number(carParkId) % 2 === 0 ? TEMP_CONTENT1 : TEMP_CONTENT2

  const getcarTypeDetailsById = async (carParkId: string) => {
    try {
      const response = await getParkingItems(carParkId);
      if (response) {
        const responseAsState = response.map((item) => {
          return {
            orderItemId: item.id.toString(),
            categoryId: item.item_category_id.toString(),
            itemTypeId: item.item_type_id.toString(),
            parkingId: item.parking_id.toString(),
            title: `${item.display_name} (${item.description})`,
            price: item.price,
            isFull: item.available <= 0,
            visible: item.visible,
            deleted: item.deleted,
            quota: item.available,
            priceId: item.stripePriceId,
            interval: item.interval,
            interval_count: item.interval_count,
          };
        });
        setCarTypeDetails(responseAsState);
      }
    } catch (error) {
      // TODO error handling
    }
  };

  useEffect(() => {
    getcarTypeDetailsById(carParkId);
  }, [carParkId]);

  return (
    <StepTwoContainer {...otherProps}>
      <Box sx={{ my: "1rem" }}> {carParkTitle}</Box>
      {(carTypeDetails &&
        carTypeDetails.length > 0 &&
        carTypeDetails?.map((detail) => {
          const _isSelected = detail.orderItemId === orderItemId;
          const show = detail.visible && !detail.deleted;
          if (show) {
            if (detail.isFull) {
              return (
                <FulledCarTypeCard
                  isSelected={_isSelected}
                  key={detail.orderItemId}
                  carTypeDetails={detail}
                  onCarTypeSelect={() => {
                    // disable waiting list flow
                    // return;
                    onCarTypeSelect(detail);
                  }}
                />
              );
            } else {
              return (
                <CarTypeCard
                  isSelected={_isSelected}
                  key={detail.orderItemId}
                  carTypeDetails={detail}
                  onCarTypeSelect={() => onCarTypeSelect(detail)}
                />
              );
            }
          }
        })) || (
        <Typography variant="h6" color="white">
          沒有可用的選項
        </Typography>
      )}
    </StepTwoContainer>
  );
};

const StepTwoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export type TCarTypeCardStyleProps = HTMLAttributes<"div"> & {
  selected: boolean;
};
export default StepTwo;
