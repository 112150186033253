import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bounce, toast } from "react-toastify";
import { getOtp } from "../../apis/otp";

interface ValidationButtonProps {
  phone: string;
}

const ValidationButton: React.FC<ValidationButtonProps> = ({ phone }) => {
  const [timer, setTimer] = useState(0);

  const triggerValidationCodeSend = async () => {
    setTimer(60); // Reset the timer to 60 seconds

    try {
      const res = await getOtp(phone);
      if (res) {
        toast(res.message === "success" ? "已發送SMS" : "發送SMS失敗", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          type: res.message === "success" ? "success" : "error",
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <>
      <Button
        sx={{
          backgroundColor: "waspDark",
          color: "waspYellow",
          ":hover": {
            backgroundColor: "waspDark",
            color: "waspYellow",
          },
          ":disabled": {
            backgroundColor: "grey",
            color: "waspLightYellow",
          },
        }}
        onClick={triggerValidationCodeSend}
        size="small"
        disabled={(timer !== 0 && timer !== 60) || phone === ""}
      >
        {timer == 0 ? "驗證碼" : `${timer}s`}
      </Button>
    </>
  );
};

export default ValidationButton;
