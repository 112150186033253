import { HTMLAttributes } from "react";

import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import styled from "styled-components";
interface IStepThreeProps {
  selectedDate: Date;
  dateOnChange: (date: Date) => void;
  selectedParkingId?: string;
}

const StepThree = (props: IStepThreeProps) => {
  const { selectedDate, dateOnChange, selectedParkingId } = props;
  const DAYS = [];

  for (let i = 1; i <= 7; i++) {
    DAYS.push(dayjs().add(i, "days"));
  }

  return (
    <Stack gap={2} my={2}>
      <Typography variant="h6" color="white">
        {selectedParkingId === "9" ? "開始日期" : "選擇開始日期"}
      </Typography>

      {selectedParkingId === "9" && (
        <DateBox sameday={1}>
          <Typography variant="body1" fontWeight="bold">
            01
          </Typography>
          <Typography variant="body2">Oct</Typography>
        </DateBox>
      )}

      {selectedParkingId !== "9" && (
        <Stack direction="row" gap={1} justifyContent="space-between">
          {DAYS.map((day, index) => {
            const _isSelected = dayjs(selectedDate).isSame(dayjs(day), "d");
            return (
              <DateBox
                key={index}
                sameday={_isSelected ? 1 : 0}
                onClick={() => dateOnChange(dayjs(day).toDate())}
              >
                <Typography variant="body1" fontWeight="bold">
                  {dayjs(day).format("DD")}
                </Typography>
                <Typography variant="body2">
                  {dayjs(day).format("MMM")}
                </Typography>
              </DateBox>
            );
          })}
        </Stack>
      )}

      <Stack flexDirection="column" gap={1}>
        <Stack>
          <Typography fontWeight="bold">起租日</Typography>
          <Typography fontWeight={900} color="white">
            {dayjs(selectedDate).format("YYYY年MM月D日")}
          </Typography>
        </Stack>
        {/* <Stack>
          <Typography fontWeight="bold">到期日</Typography>
          <Typography fontWeight={900} color="white">
            {dayjs(selectedDate).add(1, "month").format("YYYY年MM月D日")}
          </Typography>
        </Stack> */}
      </Stack>
    </Stack>
  );
};

type TDateBoxStyleProps = HTMLAttributes<"div"> & {
  sameday: 1 | 0;
};

const DateBox = styled.div<TDateBoxStyleProps>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.sameday ? "black" : "white")};
  background-color: ${(props) => (props.sameday ? "#fac80b" : "")};
  border-radius: 8px;
  min-width: 50px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: #fac80b;
  }
`;

export default StepThree;
