import { createTheme, ThemeProvider } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //CSS Style
import App from "./App";
import Layout from "./components/layout";
import "./index.css";
import Inquire from "./pages/inquire";
import Migration from "./pages/migration";
import NotFoundPage from "./pages/notFound";
import PaymentResult from "./pages/paymentResult";
import Queue from "./pages/queue";
import reportWebVitals from "./reportWebVitals";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "queue",
        element: <Queue />,
      },

      {
        path: "payment-result",
        element: <PaymentResult />,
      },
      {
        path: "inquire",
        element: <Inquire />,
      },
      {
        path: "migration",
        element: <Migration />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

interface ICustomPalette {
  waspYellow: string;
  waspLightYellow: string;
  waspDark: string;
  waspLightDark: string;
  waspRed: string;
}

//
const customPalette: ICustomPalette = {
  // Define your custom palette colors here
  waspYellow: "#fac80b",
  waspLightYellow: "#ffe6a1",
  waspDark: "#232636",
  waspLightDark: "#37416e",
  waspRed: "#842822",
};

export const customTheme = createTheme({
  palette: {
    ...createTheme().palette,
    ...customPalette,
  },
});
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <Elements stripe={stripePromise}>
    <ThemeProvider theme={customTheme}>
      <RouterProvider router={router} />
      <ToastContainer />
    </ThemeProvider>
  </Elements>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
