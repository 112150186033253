import StepperContainer from "./components/stepperContainer";

function App() {
  return (
    <>
      <StepperContainer />
    </>
  );
}

export default App;
