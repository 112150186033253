import api from "..";
import { IVerifyOtpResponse } from "./type";

export const getOtp = async (phone: string) => {
  const response = await api.get(`/otp/${phone}`);

  return response.data;
};

export const verifyOtp = async (phone: string, otp: string) => {
  const response = await api.post<IVerifyOtpResponse>(`/otp/verify`, {
    phone,
    code: otp,
  });

  return response.data;
};
