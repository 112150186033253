import { Button } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

const Header = () => {
  return (
    <HeaderWrapper>
      <a href="/">
        <Logo width={100} height={40} />
      </a>
      <Button
        href="/inquire"
        variant="contained"
        sx={{
          backgroundColor: "waspYellow",
          color: "white",
          ":hover": {
            backgroundColor: "white",
            color: "waspYellow",
          },
        }}
      >
        記錄查詢
      </Button>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid white;
`;

export default Header;
