import api from "..";
import {
  IGetMonthlyOrderResponse,
  type IGetMonthlyOrderItemsResponse,
} from "./type";

export const getAllMonthlyOrders = async () => {
  const response = await api.get<IGetMonthlyOrderResponse[]>(`/monthly_order`);

  return response.data;
};

export const getMonthlyOrderById = async (id: number) => {
  const response = await api.get<IGetMonthlyOrderResponse>(
    `/monthly_order/id/${id}`
  );

  return response.data;
};

export const getMonthlyOrdersByPhone = async (phone: string) => {
  const response = await api.get<IGetMonthlyOrderResponse[]>(
    `/monthly_order/phone/${phone}`
  );

  return response.data;
};

export const getMonthlyOrderItemById = async (id: string) => {
  const response = await api.get<IGetMonthlyOrderItemsResponse>(
    `/monthly_order_item/${id}`
  );

  return response.data;
};
