import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as WaspCar } from "../../../assets/images/wasp_car.svg";

const MovingCar: FC = () => {
  return (
    <>
      <CarWrapper>
        {/* <Car>🚕 ._-_-_</Car> */}
        <Car>
          <WaspCar /> ._-_-_
        </Car>
      </CarWrapper>
    </>
  );
};

const carMovingAnimdation = keyframes`
0% {
    transform: rotateY(180deg);
    left: -150px;
  }
  50% {
    transform: rotateY(180deg);
    left: 100%;
  }
  51% {
    transform: rotateY(0deg);
    left: calc(100% + 100px);
  }
  100% {
    transform: rotateY(0deg);
    left: -150px;
  }
`;

const CarWrapper = styled.div`
  overflow: hidden;
  font-size: 40px;
  width: 250px;
  height: 50px;
`;

const Car = styled.div`
  position: relative;
  display: inline-block;
  animation: linear infinite;
  animation-name: ${carMovingAnimdation};
  animation-duration: 10s;
  color: white;
`;

export default MovingCar;
