import { intervalMapping } from "../constants";

export const getAmountUnitText = (
  interval: string,
  interval_count: number,
  //   price: number
) => {
  if (interval_count > 1) {
    return `/${intervalMapping[interval as keyof typeof intervalMapping]}`;
  } else {
    return `/${intervalMapping[interval as keyof typeof intervalMapping]}`;
  }
};
