import {
  Button,
  Checkbox,
  Link,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getMonthlyOrderItemById } from "../../apis/order";
import type { IGetMonthlyOrderItemsResponse } from "../../apis/order/type";
import { getParkingDetail } from "../../apis/parking";
import { IGetParkingListResponse } from "../../apis/parking/type";
import {
  createSubscriptionAndOrder,
  initStripeIntents,
} from "../../apis/subscription";
import OrderDetailCard from "../../components/orderDetailCard";
import { getAmountUnitText } from "../../helper";

const CHECK_PHONE = [
  "91660637",
  "51730636",
  "67939997",
  "53399663",
  "92707925",
  "98110988",
];

interface IMigrationPage {}

type TMigrationPageProps = IMigrationPage & StackProps;

const MigrationPage: React.FC<TMigrationPageProps> = (props) => {
  const [monthlyOrderItem, setMonthlyOrderItem] =
    useState<IGetMonthlyOrderItemsResponse>();
  const [parkingDetail, setParkingDetail] = useState<IGetParkingListResponse>();
  const [tncChecked, setTncChecked] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isBinding, setIsBinding] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [readyForSubscription, setReadyForSubscription] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const type = searchParams.get("type");
  const name = searchParams.get("name");
  const email = searchParams.get("email");
  const phone = searchParams.get("phone");
  const carPlate = searchParams.get("carPlate");
  const monthlyOrderId = searchParams.get("oid");

  const stripe = useStripe();
  const elements = useElements();

  const incompleteInfo =
    !name || !email || !phone || !carPlate || !monthlyOrderId;

  const unitText = monthlyOrderItem
    ? getAmountUnitText(
        monthlyOrderItem.interval,
        monthlyOrderItem.interval_count
      )
    : "-";

  // check type is renewal and date is before validDate and other action
  useEffect(() => {
    if (!incompleteInfo) {
      const isBeforeValidDate = dayjs().isBefore("2024-08-01", "month");
      const isUserValid = CHECK_PHONE.includes(phone);

      if (type !== "renewal" || !isUserValid) {
        console.log("invalid page");
        navigate("/");
      }

      fetchMonthlyOrderItemDetail(monthlyOrderId);
    }
  }, [type]);

  // trigger stripe when ready for init
  useEffect(() => {
    if (readyForSubscription) {
      console.log("ready for stripe init");
      fetchClientSecret();
    }
  }, [readyForSubscription]);

  const fetchMonthlyOrderItemDetail = async (oid: string) => {
    try {
      const itemDetail = await getMonthlyOrderItemById(oid);
      if (itemDetail) {
        setMonthlyOrderItem(itemDetail);

        await fetchParkingDetail(itemDetail?.parking_id.toString());
      }
    } catch (error) {}
  };

  const fetchParkingDetail = async (parkingId: string) => {
    try {
      const parkingDetail = await getParkingDetail(parkingId);
      if (parkingDetail) {
        setParkingDetail(parkingDetail);
        setReadyForSubscription(true);
      }
    } catch (error) {}
  };

  // init stripe action
  const fetchClientSecret = useCallback(async () => {
    try {
      const res = await initStripeIntents(email!, name!, phone!);
      if (res) {
        const responseBody = await res;
        console.log(`init intents responseBody success`);

        setClientSecret(responseBody.client_secret);
        setCustomerId(responseBody.customer);
      }
    } catch (error) {
      //@ts-ignore
      setErrorMessage(error.message);
    }
  }, []);

  const autoCreateSubscription = async (
    payment_methodId: string,
    paymentIntentId: string,
    priceId: string
  ) => {
    try {
      setIsBinding(true);
      const createResult = await createSubscriptionAndOrder({
        priceId: priceId,
        name: name!,
        email: email!,
        phone: phone!,
        startDate: dayjs("2024-08-01").format("YYYY-MM-DD"),
        carPlate: carPlate!,
        monthlyOrderItemId: +monthlyOrderId!,
        paymentMethodId: payment_methodId,
        paymentIntentId: paymentIntentId,
        customerId: customerId,
        type: "renewal",
      });
      // error case
      if (!createResult) {
        setErrorMessage(`發生未知錯誤`);
        setIsBinding(false);
      } else {
        // normal case
        if (createResult.id) {
          navigate(`/payment-result`, {
            state: {
              orderId: createResult.id,
            },
          });
        } else {
          // special case

          if (createResult === "duplicated") {
            setErrorMessage(`此停車場已存在相同車牌的訂閱！`);
          } else if (createResult === "deleted") {
            setErrorMessage(`找不到該訂閱項目`);
          } else if (createResult === "fulled") {
            setErrorMessage(`抱歉，當前車場已滿。`);
          }
          setIsBinding(false);
        }
      }
    } catch (error) {}
  };

  const checkOutHandler = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (clientSecret) {
      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: card ? { card } : undefined,
        }
      );

      if (error) {
        //@ts-ignore
        setErrorMessage(error.message);
      } else {
        // Setup Intent was successful
        console.log("setupIntent success");
        if (setupIntent.status === "succeeded") {
          autoCreateSubscription(
            setupIntent?.payment_method as string,
            setupIntent.id,
            monthlyOrderItem?.stripePriceId!
          );
        }
      }
    } else {
      setErrorMessage("unexpected error");
    }
  };

  if (!readyForSubscription) {
    return <>Unexpected Error</>;
  }
  return (
    <Stack gap={2}>
      {/* <Typography variant="h6" color="white">
        請注意，您正在進行資料轉移步驟，請核對以下資料及新增信用卡以進行訂閱。（此頁面會於2024年8月1號失效）
      </Typography> */}

      <Typography variant="button" textAlign="center">
        訂閱項目: {monthlyOrderItem?.display_name}
      </Typography>
      {!incompleteInfo && readyForSubscription && (
        <OrderDetailCard
          name={name}
          email={email}
          phone={phone}
          carPlate={carPlate}
          parkingName={parkingDetail?.display_name}
          parkingAddress={parkingDetail?.location}
          startDate={"2024-08-01"}
          endDate={"2024-09-01"}
          amount={monthlyOrderItem?.price}
          amountUnit={unitText}
          interval_count={monthlyOrderItem?.interval_count}
          subscriptionItem={monthlyOrderItem?.display_name}
        />
      )}

      <Stack>
        <Typography variant="caption" color="white" fontWeight="bold" px={1.5}>
          {`*注意: 訂閱車位後，系統將於起租日起每月 /
          每季(1號)信用卡自動收費，如屆時未能從綁定的信用卡中扣款，將會視為取消訂閱，停車權有可能被取消，如有任何問題請聯絡客服
          3611 7688。`}
        </Typography>

        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={tncChecked}
            onChange={() => setTncChecked((prev) => !prev)}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "waspYellow",
              },
            }}
          />
          <Typography color="white" variant="button">
            我同意
            <Link
              href="https://parkingbees.com.hk/assets/pdf/parkingbees-tnc.pdf"
              underline="hover"
              color="waspYellow"
              target="_blank"
              borderColor="waspYellow"
              ml="2px"
            >
              條款及細則
            </Link>
            及
            <Link
              href="https://parkingbees.com.hk/assets/pdf/parkingbees-monthly-tnc.pdf"
              underline="hover"
              color="waspYellow"
              target="_blank"
              borderColor="waspYellow"
              ml="2px"
            >
              月租車位使用條款
            </Link>
          </Typography>
        </Stack>
        {clientSecret && (
          <Stack
            justifyContent="center"
            borderRadius={2}
            mb={1}
            p={2}
            bgcolor="white"
          >
            <CardElement
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                    fontSize: "16px",
                    color: "#fac80b",
                    "::placeholder": {
                      color: "#232636",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                hidePostalCode: true,
              }}
            />
          </Stack>
        )}
        {errorMessage && (
          <Typography color="error" mb={2} px={1}>
            {errorMessage}
          </Typography>
        )}
        <Button
          onClick={checkOutHandler}
          variant="contained"
          fullWidth
          type="submit"
          disabled={!stripe || !tncChecked || isBinding}
          sx={{
            background: "waspYellow",
            color: "black",
            ":hover": {
              backgroundColor: "waspYellow",
              color: "black",
            },
            ":disabled": {
              backgroundColor: "grey",
              color: "white",
            },
          }}
        >
          {isBinding ? "訂閱中..." : `訂閱`}
        </Button>
      </Stack>
    </Stack>
  );
};

export default MigrationPage;
