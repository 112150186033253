import axios, { AxiosInstance, type AxiosResponse } from "axios";
import { Bounce, toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// console.log(`API_BASE_URL`);
// console.log(API_BASE_URL);

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your API base URL
  timeout: 3000, // Set a timeout value if needed
  headers:
    {
      "Content-Type": "application/json", // Set the desired content type
    } || {},
});

// Add a request interceptor
// api.interceptors.request.use(
//   //@ts-ignore
//   (config: AxiosRequestConfig) => {
//     // Do something before sending the request
//     console.log("Request is being sent");
//     return config;
//   },
//   (error) => {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    // console.log("Response was received");
    // console.log(response);
    return response;
  },
  (error) => {
    const errorMessage = error.response.data.message;
    toast.error(errorMessage, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
